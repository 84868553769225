<template>
  <section>
    <product-detail-main />
  </section>
</template>

<script>
export default {
  name: 'ProductDetail',
  components: {
    ProductDetailMain: () => import('@/components/Products/Detail/ProductDetailMain.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
